import classNames from 'classnames/bind';
import styles from './CreateMailTemplate.module.scss';
import { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import GlobalStateContext from '~/GlobalStateContext ';
import API_URL from '~/config';
import CreatableSelect from 'react-select/creatable';

const cx = classNames.bind(styles);


function CreateMailTemplate() {
    const { token, setToken } = useContext(GlobalStateContext);
    const baseToken = localStorage.getItem("access_token");

    const refdiv = useRef(null);
    const rteRef = useRef(null);

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [fileLinks, setFileLinks] = useState([]);
    const [newFileLink, setNewFileLink] = useState('');
    const [newFileName, setNewFileName] = useState('');
    const [loading, setLoading] = useState(false);
    const [isAccessible, setIsAccessible] = useState(true);

    useEffect(() => {
        checkAccess('CreateMailTemplate');
    }, []);

    const checkAccess = async (func) => {
        try {
            const response = await axios.post(`${API_URL}/admin/check-access`, { function: func }, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            });
            setIsAccessible(response.data.message === 'Authorized');
        } catch (error) {
            console.error("Access check failed:", error);
            setIsAccessible(false);
        }
    };

    useEffect(() => {
        // Fetch all templates when component mounts
        fetchTemplates();

        // Khởi tạo RichTextEditor khi component mount
        rteRef.current = new window.RichTextEditor(refdiv.current);
        rteRef.current.setHTMLCode("");
    }, [token, baseToken]);

    const fetchTemplates = () => {
        axios
            .get(`${API_URL}/templates`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            })
            .then((response) => {
                if (response.data.success && Array.isArray(response.data.templates)) {
                    setTemplates(response.data.templates);
                } else {
                    console.error("API response is not as expected:", response.data);
                }
            })
            .catch((error) => {
                console.error("Error fetching templates:", error);
            });
    };

    const handleTemplateChange = (selectedOption) => {
        if (selectedOption) {
            const template = templates.find(t => t.id === selectedOption.value);
            setSelectedTemplate(template);
            rteRef.current.setHTMLCode(template.content);
            setFileLinks(template.file_links || []);
        } else {
            setSelectedTemplate(null);
            rteRef.current.setHTMLCode("");
            setFileLinks([]);
        }
    };

    const handleCreateTemplate = (inputValue) => {
        const currentContent = rteRef.current.getHTMLCode();
        if (selectedTemplate && selectedTemplate.id === null) {
            setSelectedTemplate(prev => ({ ...prev, name: inputValue }));
        } else if (!selectedTemplate && currentContent.trim() !== "") {
            const newTemplate = { id: null, name: inputValue, content: currentContent, file_links: fileLinks };
            setTemplates(prev => [...prev, newTemplate]);
            setSelectedTemplate(newTemplate);
        } else {
            const newTemplate = { id: null, name: inputValue, content: "", file_links: [] };
            setTemplates(prev => [...prev, newTemplate]);
            setSelectedTemplate(newTemplate);
            rteRef.current.setHTMLCode("");
            setFileLinks([]);
        }
    };


    const handleAddFileLink = () => {
        if (isValidURL(newFileLink) && isValidFileNames([newFileName])) {
            setFileLinks([...fileLinks, { url: newFileLink, name: newFileName }]);
            setNewFileLink('');
            setNewFileName('');
        } else {
            alert('Invalid URL format or file name extension!');
        }
    };

    const isValidFileNames = (fileNames) => {
        const validExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'gif'];
        return fileNames.every((fileName) => {
            const extension = fileName.split('.').pop().toLowerCase();
            return fileName.trim().length > 0 && validExtensions.includes(extension);
        });
    };

    const isValidURL = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const btnclickCreate = () => {
        setLoading(true);
        const templateContent = rteRef.current.getHTMLCode();

        const data = {
            template_name: selectedTemplate ? selectedTemplate.name : "New Template",
            template_content: templateContent,
            file_links: fileLinks,
        };

        const request = selectedTemplate && selectedTemplate.id
            ? axios.put(`${API_URL}/templates/${selectedTemplate.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                    "Content-Type": "application/json",
                },
            })
            : axios.post(`${API_URL}/templates`, data, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                    "Content-Type": "application/json",
                },
            });

        request
            .then((response) => {
                console.log("Template created/updated successfully:", response.data);
                alert("Template created/updated successfully!");
                fetchTemplates();
            })
            .catch((error) => {
                console.error("Error creating/updating template:", error);
                alert("Error creating/updating template: " + error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const btnclickDelete = () => {
        if (selectedTemplate && selectedTemplate.id) {
            const confirmDelete = window.confirm("Are you sure you want to delete this template?");
            if (confirmDelete) {
                setLoading(true);
                axios.delete(`${API_URL}/templates/${selectedTemplate.id}`, {
                    headers: {
                        Authorization: `Bearer ${token || baseToken}`,
                    },
                })
                    .then((response) => {
                        console.log("Template deleted successfully:", response.data);
                        alert("Template deleted successfully!");
                        setTemplates(templates.filter(t => t.id !== selectedTemplate.id));
                        setSelectedTemplate(null);
                        rteRef.current.setHTMLCode("");
                        setFileLinks([]);
                    })
                    .catch((error) => {
                        console.error("Error deleting template:", error);
                        alert("Error deleting template: " + error.response.data.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } else {
            rteRef.current.setHTMLCode("");
            setFileLinks([]);
        }
    };

    if (!isAccessible) {
        return <div className={cx('error')}> Unauthorized </div>;
    }


    return (
        <div className={cx('container')}>
            <div>
                <h3>Select or Create Template to Edit:</h3>
                <CreatableSelect
                    options={templates.map((template) => ({
                        value: template.id,
                        label: template.name,
                    }))}
                    onChange={handleTemplateChange}
                    onCreateOption={handleCreateTemplate}
                    value={selectedTemplate ? { value: selectedTemplate.id, label: selectedTemplate.name } : null}
                />
            </div>
            <div ref={refdiv}></div>
            <div className={cx('input-group')}>
                <label htmlFor="fileLink">File Link</label>
                <input
                    type="text"
                    id="fileLink"
                    value={newFileLink}
                    onChange={(e) => setNewFileLink(e.target.value)}
                    placeholder="Enter file link"
                />
            </div>
            <div className={cx('input-group')}>
                <label htmlFor="fileName">File Name</label>
                <input
                    type="text"
                    id="fileName"
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                    placeholder="Enter file name"
                />
            </div>
            <div className={cx('button-group')}>
                <button onClick={handleAddFileLink}>Add File Link</button>
            </div>
            <div className={cx('file-links')}>
                <ul>
                    {fileLinks.map((file, index) => (
                        <li key={index} className={cx('file-item')}>
                            <div className={cx('file-info')}>
                                <span className={cx('file-name')}>{file.name}</span>
                                <a href={file.url} target="_blank" rel="noopener noreferrer" className={cx('file-url')}>
                                    {file.url}
                                </a>
                            </div>
                            <button onClick={() => {
                                setFileLinks(fileLinks.filter((_, i) => i !== index));
                            }} className={cx('remove-button')}>×</button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={cx('button-group')}>
                <button onClick={btnclickCreate} disabled={loading}>
                    {loading ? 'Loading...' : 'Create/Update Template'}
                </button>
                <button onClick={btnclickDelete} disabled={loading}>
                    {loading ? 'Loading...' : 'Delete Template'}
                </button>
            </div>
        </div>
    );
}


export default CreateMailTemplate;