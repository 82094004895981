import classNames from 'classnames/bind';
import styles from './ChangePassword.module.scss';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import axios from 'axios';
import GlobalStateContext from '~/GlobalStateContext ';
import API_URL from '~/config';

const cx = classNames.bind(styles);

function ChangePassword() {
    const { token, setToken } = useContext(GlobalStateContext);
    const baseToken = localStorage.getItem('access_token');

    const [formData, setFormData] = useState({
        old_password: '',
        new_password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate input
        if (!formData.new_password || !formData.old_password) {
            alert('Please fill in all fields.');
            return;
        }

        try {
            // Send form data to the server
            const data = await axios.post(`${API_URL}/change-password`, formData, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            });
            // Set state or perform other actions after successful password change
            if (data.status === 200) {
                alert('Password changed successfully');
            }
        } catch (error) {
            alert(`Error changing password: ${error.response.data.message}`);
        }
    };

    return (
        <div className={cx('login')}>
            <div className={cx('container', 'login__wrapper')} style={{ padding: '0px' }}>
                <form className={cx('login__form')} onSubmit={handleSubmit}>
                    <div className={cx('login__form-group')}>
                        <label>Old Password</label>
                        <input
                            name="old_password"
                            type="password"
                            placeholder="Enter your current password"
                            required
                            value={formData.old_password}
                            onChange={handleChange}
                        />
                    </div>

                    <div className={cx('login__form-group')}>
                        <label>New Password</label>
                        <input
                            name="new_password"
                            type="password"
                            placeholder="Enter your new password"
                            required
                            value={formData.new_password}
                            onChange={handleChange}
                        />
                    </div>

                    <div className={cx('login__form-submit')}>
                        <button>Change Password</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChangePassword;
