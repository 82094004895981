import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames/bind';
import styles from './ChangeMailConfig.module.scss';
import GlobalStateContext from '~/GlobalStateContext ';
import API_URL from '~/config';

const cx = classNames.bind(styles);

function ChangeMailConfig() {
    const { token } = useContext(GlobalStateContext);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [configs, setConfigs] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        mailer: '',
        host: '',
        port: '',
        username: '',
        password: '',
        encryption: '',
        from_address: '',
        from_name: '',
    });

    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isAccessible, setIsAccessible] = useState(true);


    const checkAccess = async (func) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/admin/check-access`, { function: func }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsAccessible(response.data.message === 'Authorized');
        } catch (error) {
            console.error("Access check failed:", error);
            setIsAccessible(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAccess('CreateSender');
    }, []);


    useEffect(() => {
        fetchUserRole();
        fetchMailConfigs();
    }, []);

    const fetchUserRole = async () => {
        try {
            const response = await axios.get(`${API_URL}/admins`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsSuperAdmin(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsSuperAdmin(false);
            }
        }
    };

    const fetchMailConfigs = async () => {
        try {
            const response = await axios.get(`${API_URL}/admin/mail-config`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setConfigs(response.data.configs);
        } catch (error) {
            console.error('Error fetching mail configs:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCancel = (e) => {
        setFormData({
            name: '',
            mailer: '',
            host: '',
            port: '',
            username: '',
            password: '',
            encryption: '',
            from_address: '',
            from_name: '',
        });
        setAdding(false);
        setEditing(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${API_URL}/admin/mail-config`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Mail config updated successfully');
            setEditing(false);
            fetchMailConfigs();
        } catch (error) {
            alert(`Error updating mail config: ${error.response.data.message}`);
        }
    };

    const handleAddMailConfig = async () => {
        try {
            await axios.post(`${API_URL}/admin/mail-config`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('New mail config added successfully');
            setFormData({
                name: '',
                mailer: '',
                host: '',
                port: '',
                username: '',
                password: '',
                encryption: '',
                from_address: '',
                from_name: '',
            });
            setAdding(false);
            fetchMailConfigs();
        } catch (error) {
            alert(`Error adding new mail config: ${error.response.data.message}`);
        }
    };

    const handleDeleteMailConfig = async (id) => {
        try {
            await axios.delete(`${API_URL}/admin/mail-config/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Mail Sender deleted successfully');
            fetchMailConfigs();
        } catch (error) {
            alert(`Error deleting Mail Sender: ${error.response.data.message}`);
        }
    };

    const handleSetDefault = async (id) => {
        try {
            await axios.post(`${API_URL}/admin/mail-config/set-default/${id}`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Mail Sender set as default successfully');
            fetchMailConfigs();
        } catch (error) {
            alert(`Error setting Mail Sender as default: ${error.response.data.message}`);
        }
    };

    if (loading) {
        return <div className={cx('loading')}>Loading...</div>;
    }

    if (!isAccessible) {
        return <div className={cx('error')}> Unauthorized </div>;
    }


    return (
        <div className={cx('change-mail-config')}>
            <div className={cx('change-mail-config__wrapper')}>
                {!editing ? (
                    <div style={{ width: '100%' }}>
                        <h2>Current Mail Sender</h2>
                        <table className={cx('styled-table')}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Mailer</th>
                                    <th>Host</th>
                                    <th>Port</th>
                                    <th>Username</th>
                                    <th>Encryption</th>
                                    <th>From Address</th>
                                    <th>From Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {configs.map((config) => (
                                    <tr key={config.id}>
                                        <td>{config.name}</td>
                                        <td>{config.mailer}</td>
                                        <td>{config.host}</td>
                                        <td>{config.port}</td>
                                        <td>{config.username}</td>
                                        <td>{config.encryption}</td>
                                        <td>{config.from_address}</td>
                                        <td>{config.from_name}</td>
                                        <td className={cx('button-group')}>
                                            <button onClick={() => {
                                                setFormData(config);
                                                setEditing(true);
                                            }}>Edit</button>
                                            <button onClick={() => handleDeleteMailConfig(config.id)}>Delete</button>
                                            {isSuperAdmin && !config.is_default && (
                                                <button onClick={() => handleSetDefault(config.id)}>Set as Default</button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button onClick={() => setAdding(true)}>Add Sender</button>
                    </div>
                ) : (
                    <form className={cx('change-mail-config__form')} onSubmit={handleSubmit}>
                        {Object.entries(formData).map(([key, value]) => (
                            key !== 'is_default' && (
                                <div className={cx('change-mail-config__form-group')} key={key}>
                                    <label>{key}</label>
                                    <input
                                        name={key}
                                        type={key === 'password' ? 'password' : 'text'}
                                        placeholder={`Enter ${key.toLowerCase()}`}
                                        value={key === 'password' ? '' : value}
                                        onChange={handleChange}
                                        disabled={key === 'id' || key === 'user_id'}
                                    />
                                </div>
                            )
                        ))}
                        <div className={cx('change-mail-config__form-submit')}>
                            <button style={{ margin: '10px' }}>Save Changes</button>
                            <button
                                style={{ margin: '10px' }}
                                type="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                )}
            </div>
            {adding && (
                <div className={cx('change-mail-config__form')}>
                    {Object.entries(formData).map(([key, value]) => (
                        <div className={cx('change-mail-config__form-group')} key={key}>
                            <label>{key}</label>
                            <input
                                name={key}
                                type={key === 'password' ? 'password' : 'text'}
                                placeholder={`Enter ${key.toLowerCase()}`}
                                value={value}
                                onChange={handleChange}
                            />
                        </div>
                    ))}
                    <div className={cx('change-mail-config__form-submit')}>
                        <button style={{ margin: '10px' }} onClick={handleCancel}>Cancel</button>
                        <button style={{ margin: '10px' }} onClick={handleAddMailConfig}>Add Sender</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChangeMailConfig;