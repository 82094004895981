import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './AdminLayout.module.scss';
import f1 from '~/assets/images/footer/f1.png';
import axios from 'axios';
import GlobalStateContext from '~/GlobalStateContext ';
import { useContext } from 'react';
import API_URL from '~/config';

const cx = classNames.bind(styles);

const AdminLayout = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const accessToken = localStorage.getItem('access_token');
    const { token, setToken } = useContext(GlobalStateContext);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleOutsideClick = (e) => {
        if (!e.target.closest(`.${cx('admin__sidebar')}`) && !e.target.closest(`.${cx('admin__hamburger')}`)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMenuOpen]);

    const onLogout = () => {
        axios
            .post(`${API_URL}/logout`, null, {
                headers: {
                    Authorization: `Bearer ${token || accessToken}`,
                },
            })
            .then((response) => {
                setToken('');
                localStorage.clear();
                window.location.reload();
            })
            .catch((error) => {
                console.error('Error logging out:', error);
                setToken('');
                localStorage.clear();
                window.location.reload();
            });
    };

    return (
        <div className={cx('admin')}>
            <div className={cx('admin__top')}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <div className={cx('header__logo')} style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <img className={cx('header__logo-img')} src={f1} alt="Logo" />
                        <span
                            className={cx('header__logo-text')}
                            style={{
                                fontSize: '23px',
                                color: '#fff',
                                fontWeight: '500',
                                marginLeft: '10px',
                                lineHeight: '1',
                            }}
                        >
                            Odyssey
                        </span>
                    </div>
                </Link>
                <div className={cx('admin__hamburger')} onClick={handleMenuToggle}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <button className={cx('admin__logout')} onClick={onLogout}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        style={{ width: "40px", height: "40px" }} // Explicit dimensions
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                        />
                    </svg>
                    <span>Logout</span>
                </button>
            </div>
            <div className={cx('admin__main')}>
                <div className={cx('admin__sidebar', { 'admin__sidebar--open': isMenuOpen })}>
                    <div className={cx('admin__sidebar-user')}>
                        <span>Admin</span>
                        <button onClick={onLogout}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                style={{ width: "40px", height: "40px" }} // Explicit dimensions
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                                />
                            </svg>
                            <span>Logout</span>
                        </button>
                    </div>
                    <div className={cx('admin__item')}>
                        <NavLink to="/list-contact-admin">Contact List</NavLink>
                    </div>

                    <div className={cx('admin__item')}>
                        <NavLink to="/list-contact-subscribe">Subscribe List</NavLink>
                    </div>
                    <div className={cx('admin__item')}>
                        <NavLink to="/create-mail-template">Create Mail Template</NavLink>
                    </div>
                    <div className={cx('admin__item')}>
                        <NavLink to="/send-mail_template">Send Mail</NavLink>
                    </div>

                    <div className={cx('admin__item')}>
                        <NavLink to="/change-password">Change Password</NavLink>
                    </div>

                    <div className={cx('admin__item')}>
                        <NavLink to="/mail-config">Create Sender</NavLink>
                    </div>

                    <div className={cx('admin__item')}>
                        <NavLink to="/manage-account">Manage Account</NavLink>
                    </div>
                </div>
                <div className={cx('admin__content')}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdminLayout;
