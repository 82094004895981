import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import classNames from 'classnames/bind';
import styles from './ManageAccount.module.scss';
import GlobalStateContext from '~/GlobalStateContext ';
import API_URL from '~/config';
import Select from 'react-select';


const cx = classNames.bind(styles);

const accessibleFunctionsOptions = [
    { value: 'ContactList', label: 'ContactList' },
    { value: 'SubscribeList', label: 'SubscribeList' },
    { value: 'CreateMailTemplate', label: 'CreateMailTemplate' },
    { value: 'SendMail', label: 'SendMail' },
    { value: 'CreateSender', label: 'CreateSender' },
];

function ManageAccount() {
    const { token } = useContext(GlobalStateContext);
    const [admins, setAdmins] = useState([]);
    const [isSuperAdmin, setIsSuperAdmin] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        accessible_functions: [],
    });
    const [editingAdmin, setEditingAdmin] = useState(null);

    const fetchAdmins = async () => {
        try {
            const response = await axios.get(`${API_URL}/admins`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAdmins(response.data.admins);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching admins:', error);
            setIsSuperAdmin(false);
            setError('Unauthorized');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAdmins();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (selectedOptions) => {
        setFormData({
            ...formData,
            accessible_functions: selectedOptions ? selectedOptions.map(option => option.value) : [],
        });
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            email: '',
            password: '',
            accessible_functions: [],
        });
        setEditingAdmin(null);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingAdmin) {
                await axios.put(`${API_URL}/admins/${editingAdmin.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                alert('Admin updated successfully');
            } else {
                await axios.post(`${API_URL}/admins`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                alert('Admin created successfully');
            }
            setFormData({
                name: '',
                email: '',
                password: '',
                accessible_functions: [],
            });
            setEditingAdmin(null);
            fetchAdmins();
        } catch (error) {
            alert(`Error: ${error.response.data.message}`);
        }
    };

    const handleEdit = (admin) => {
        setFormData({
            name: admin.name,
            email: admin.email,
            password: '',
            accessible_functions: admin.accessible_functions || [],
        });
        setEditingAdmin(admin);
    };

    const handleDelete = async (id, email) => {
        const confirmDelete = window.confirm(`Do you want to delete the admin with email: ${email}?`);
        if (!confirmDelete) {
            return;
        }

        try {
            await axios.delete(`${API_URL}/admins/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            alert('Admin deleted successfully');
            if (editingAdmin && editingAdmin.id === id) {
                setFormData({
                    name: '',
                    email: '',
                    password: '',
                    accessible_functions: [],
                });
                setEditingAdmin(null);
            }
            fetchAdmins();
        } catch (error) {
            alert(`Error deleting admin: ${error.response.data.message}`);
        }
    };

    if (loading) {
        return <div className={cx('loading')}>Loading...</div>;
    }

    if (!isSuperAdmin) {
        return <div className={cx('error')}>{error}</div>;
    }

    return (
        <div className={cx('account-manager')}>
            <h2>Admin Management</h2>
            <form className={cx('admin-form')} onSubmit={handleSubmit}>
                {editingAdmin && (
                    <div className={cx('form-group')}>
                        <label>ID</label>
                        <input
                            type="text"
                            name="id"
                            value={editingAdmin.id}
                            disabled={true}
                        />
                    </div>
                )}
                <div className={cx('form-group')}>
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={cx('form-group')}>
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={cx('form-group')}>
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required={!editingAdmin}
                    />
                </div>
                <div className={cx('form-group')}>
                    <label>Accessible Functions</label>
                    <Select
                        isMulti
                        name="accessible_functions"
                        options={accessibleFunctionsOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={accessibleFunctionsOptions.filter(option => formData.accessible_functions.includes(option.value))}
                        onChange={handleSelectChange}
                    />
                </div>
                <button type="submit">{editingAdmin ? 'Update Admin' : 'Create Admin'}</button>
                {editingAdmin && (
                    <button type="button" onClick={handleCancel}>Cancel</button>
                )}
            </form>
            <table className={cx('styled-table')}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Accessible Functions</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {admins.map((admin) => (
                        <tr key={admin.id}>
                            <td>{admin.name}</td>
                            <td>{admin.email}</td>
                            <td>
                                {Array.isArray(admin.accessible_functions) && admin.accessible_functions.length > 0 ? (
                                    admin.accessible_functions.map((func, index) => (
                                        <span key={index} className={cx('code-block')}>
                                            {func}
                                        </span>
                                    ))
                                ) : (
                                    <span className={cx('code-block')}>No functions assigned</span>
                                )}
                            </td>
                            <td>
                                <button onClick={() => handleEdit(admin)}>Edit</button>
                                <button onClick={() => handleDelete(admin.id, admin.email)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ManageAccount;