import classNames from 'classnames/bind';
import styles from './SendMailTemplate.module.scss';
import { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import GlobalStateContext from '~/GlobalStateContext ';
import API_URL from '~/config';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const cx = classNames.bind(styles);

function SendMailTemplate() {
    const { token } = useContext(GlobalStateContext);
    const baseToken = localStorage.getItem("access_token");

    const refdiv = useRef(null);
    const rteRef = useRef(null);

    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [receivers, setReceivers] = useState([]);
    const [selectedReceivers, setSelectedReceivers] = useState([]);
    const [receiverTags, setReceiverTags] = useState({});
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [mailConfigs, setMailConfigs] = useState([]);
    const [selectedMailConfig, setSelectedMailConfig] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAccessible, setIsAccessible] = useState(true);


    const checkAccess = async (func) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/admin/check-access`, { function: func }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsAccessible(response.data.message === 'Authorized');
        } catch (error) {
            console.error("Access check failed:", error);
            setIsAccessible(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAccess('SendMail');
    }, []);

    useEffect(() => {
        fetchTemplates();
        fetchMailConfigs();
        fetchReceivers();
        fetchSubjects();
        initializeRichTextEditor();
    }, [token, baseToken]);

    const fetchTemplates = async () => {
        try {
            const response = await axios.get(`${API_URL}/templates`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            });
            setTemplates(response.data.templates);
        } catch (error) {
            console.error('Error fetching templates:', error);
        }
    };

    const fetchMailConfigs = async () => {
        try {
            const response = await axios.get(`${API_URL}/admin/mail-config`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            });
            setMailConfigs(response.data.configs);
        } catch (error) {
            console.error('Error fetching mail configs:', error);
        }
    };

    const fetchReceivers = async () => {
        try {
            const response = await axios.get(`${API_URL}/receivers`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            });
            setReceivers(response.data.receivers);
        } catch (error) {
            console.error('Error fetching receivers:', error);
        }
    };

    const fetchSubjects = async () => {
        try {
            const response = await axios.get(`${API_URL}/subjects`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            });
            setSubjects(response.data.subjects.map(subject => ({ value: subject.id, label: subject.name })));
        } catch (error) {
            console.error('Error fetching subjects:', error);
        }
    };

    const initializeRichTextEditor = () => {
        try {
            rteRef.current = new window.RichTextEditor(refdiv.current);
            rteRef.current.setHTMLCode("Hello World!");
        } catch (error) {
            console.error("Error initializing RichTextEditor:", error);
        }
    };

    const handleTemplateChange = (selectedOption) => {
        const template = templates.find(t => t.id === selectedOption.value);
        setSelectedTemplate(template);
    };

    const handleReceiversChange = (selectedOptions) => {
        let newSelectedReceivers;
        if (selectedOptions.some(option => option.value === 'select_all')) {
            newSelectedReceivers = receiverOptions.filter(option => option.value !== 'select_all');
        } else {
            newSelectedReceivers = selectedOptions;
        }
        setSelectedReceivers(newSelectedReceivers);

        const newReceiverTags = {};
        newSelectedReceivers.forEach(option => {
            const receiver = receivers.find(r => r.email === option.value);
            newReceiverTags[option.value] = receiver ? receiver.tags.reduce((acc, tag) => {
                acc[tag.name] = tag.value;
                return acc;
            }, {}) : {};
        });
        setReceiverTags(newReceiverTags);
    };

    const handleTagChange = (email, tagName, value) => {
        setReceiverTags(prev => ({
            ...prev,
            [email]: {
                ...prev[email],
                [tagName]: value
            }
        }));
    };

    const handleSubjectChange = (selectedOption) => {
        setSelectedSubject(selectedOption);
    };

    const handleCreateSubject = (inputValue) => {
        const newSubject = { value: inputValue, label: inputValue };
        setSubjects(prev => [...prev, newSubject]);
        setSelectedSubject(newSubject);
    };

    const isValidEmail = (email) => {
        // Simple email validation regex
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleCreate = (inputValue) => {
        if (isValidEmail(inputValue)) {
            const newOption = { value: inputValue, label: inputValue };
            setSelectedReceivers((prev) => [...prev, newOption]);
        } else {
            alert("Invalid email address");
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const requestBody = {
            template_id: selectedTemplate.id,
            receivers: selectedReceivers.map(receiver => ({
                email: receiver.value,
                tags: receiverTags[receiver.value]
            })),
            subject: selectedSubject.label,
            mail_config: selectedMailConfig.username,
        };

        axios.post(`${API_URL}/send-email`, requestBody, {
            headers: {
                Authorization: `Bearer ${token || baseToken}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                alert("Email sent successfully!");
                console.log("Email sent successfully:", response.data);
            })
            .catch(error => {
                alert("Error sending email!");
                console.error("Error sending email:", error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const receiverOptions = [
        { value: 'select_all', label: 'Select All' },
        ...receivers.map(receiver => ({
            value: receiver.email,
            label: receiver.email,
        }))
    ];

    const getPreviewContent = () => {
        if (!selectedTemplate) return '';

        let content = selectedTemplate.content;
        selectedReceivers.forEach(receiver => {
            const tags = receiverTags[receiver.value];
            debugger
            if (tags) {
                Object.keys(tags).forEach(tag => {
                    const regex = new RegExp(`{{${tag}}}`, 'gi');
                    content = content.replace(regex, tags[tag]);
                });
            }
        });
        return content;
    };

    if (!isAccessible) {
        return <div className={cx('error')}> Unauthorized </div>;
    }
    return (
        <div className={cx('container')}>
            <div>
                <h3>Select Template:</h3>
                <Select
                    options={templates.map((template) => ({
                        value: template.id,
                        label: template.name,
                    }))}
                    onChange={handleTemplateChange}
                />
            </div>

            <div className={cx('input-group')}>
                <label htmlFor="mailConfig">Select Sender</label>
                <Select
                    options={mailConfigs.map((config) => ({
                        value: config.id,
                        label: config.name,
                        username: config.username,
                    }))}
                    onChange={(option) => setSelectedMailConfig(option)}
                    value={selectedMailConfig}
                />
            </div>

            <div>
                <h3>Select Receivers:</h3>
                <CreatableSelect
                    isMulti
                    options={receiverOptions}
                    onChange={handleReceiversChange}
                    onCreateOption={handleCreate}
                    value={selectedReceivers}
                />
            </div>

            <div>
                <h3>Select Subject:</h3>
                <CreatableSelect
                    options={subjects}
                    onChange={handleSubjectChange}
                    onCreateOption={handleCreateSubject}
                    value={selectedSubject}
                />
            </div>

            {selectedReceivers.length > 0 && (
                <div>
                    <h4>Receiver Tags:</h4>
                    {selectedReceivers.map((receiverOption) => (
                        <div key={receiverOption.value}>
                            <h5>{receiverOption.label}</h5>
                            <ul>
                                {selectedTemplate && selectedTemplate.tags.map(tag => (
                                    <li key={tag.id}>
                                        {tag.name}:
                                        <input
                                            type="text"
                                            value={receiverTags[receiverOption.value]?.[tag.name] || ''}
                                            onChange={(e) => handleTagChange(receiverOption.value, tag.name, e.target.value)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            )}

            {selectedTemplate && (
                <div>
                    <h3>Preview:</h3>
                    <div dangerouslySetInnerHTML={{ __html: getPreviewContent() }}></div>
                </div>
            )}

            <div className={cx('button-group')}>
                <button onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Loading...' : 'Send Email'}
                </button>
            </div>
        </div>
    );
}

export default SendMailTemplate;