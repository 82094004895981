import classNames from 'classnames/bind';
import styles from './ListContactAdmin.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import GlobalStateContext from '~/GlobalStateContext ';
import API_URL from '~/config';

const cx = classNames.bind(styles);

function ListContactAdmin() {
    const [listContact, setListContact] = useState([]);
    const { token, setToken } = useContext(GlobalStateContext);
    const baseToken = localStorage.getItem('access_token');
    const [loading, setLoading] = useState(false);
    const [isAccessible, setIsAccessible] = useState(true);


    const checkAccess = async (func) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}/admin/check-access`, { function: func }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsAccessible(response.data.message === 'Authorized');
        } catch (error) {
            console.error("Access check failed:", error);
            setIsAccessible(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAccess('ContactList');
    }, []);

    useEffect(() => {
        fetchListContact();
    }, []); // Call once when the component is rendered

    const fetchListContact = () => {
        axios
            .get(`${API_URL}/admin/contact`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            })
            .then((response) => {
                setListContact(response.data.data);
            })
            .catch((error) => {
                // Handle error
                console.error('Error fetching data:', error);
            });
    };

    const handleDelete = (id) => {
        const confirmDelete = window.confirm(`Do you want to delete the record with ID ${id}?`);
        if (!confirmDelete) {
            return;
        }

        axios
            .delete(`${API_URL}/admin/contact/${id}`, {
                headers: {
                    Authorization: `Bearer ${token || baseToken}`,
                },
            })
            .then((response) => {
                alert('Deleted successfully');
                // After successful deletion, call the GET API again to update the list
                fetchListContact();
            })
            .catch((error) => {
                // Handle error
                console.error('Error deleting data:', error);
            });
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };

    if (loading) {
        return <div className={cx('loading')}>Loading...</div>;
    }

    if (!isAccessible) {
        return <div className={cx('error')}> Unauthorized </div>;
    }

    return (
        <div>
            <table className={cx('styled-table')}>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Company</th>
                        <th>Phone</th>
                        <th>Job Title</th>
                        <th>Message</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {listContact?.map((item, index) => {
                        return (
                            <tr key={item.id}>
                                <th>{index + 1}</th>
                                <th>{item.first_name}</th>
                                <th>{item.last_name}</th>
                                <th>{item.email}</th>
                                <th>{item.company}</th>
                                <th>{item.phone}</th>
                                <th>{item.job_title}</th>
                                <th>{item.yours_messenger}</th>
                                <th>{formatDateTime(item.created_at)}</th>
                                <th style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <button
                                        className={cx('a1')}
                                        onClick={() => {
                                            handleDelete(item.id);
                                        }}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                            />
                                        </svg>
                                    </button>
                                </th>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ListContactAdmin;
